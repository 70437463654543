import React from "react";
import { styled } from "../../stitches.config";

import Link from "../Link";

// slide w uniwersalnym bloku minisite w kompozycji 
// treści edytowalne są przez bo 

const MiniSite = props => {
    const { article, notRegular } = props;
    const sponsor = (article.sponsor ? <Sponsor>{article.sponsor}</Sponsor> : null);
    const sponsorExtra = (article.sponsor ? <SponsorExtra>{article.sponsor}</SponsorExtra> : null);


    return (
        <>
            {notRegular ?
                <Link to={article.link}>
                    <ImageWrapperNotRegular>
                        <img src={article.img} />
                        <div className="minisite-title">
                            <span>{article.title}</span>
                        </div>
                        {sponsor}
                    </ImageWrapperNotRegular>
                </Link>
                :
                <BoxWrapper className="minisite-box">
                    <Link to={article.link}>
                        <ImageWrapper>
                            <img src={article.img} />
                            {sponsorExtra}
                        </ImageWrapper>
                        <Title className="photo-on-top__title">
                            {article.title}
                        </Title>
                        <Description>
                            {article.description}
                        </Description>
                    </Link>
                </BoxWrapper>
            }
        </>
    );
};

// ustawienia do slidera (małe bloki)


const Description = styled("span", {
    fontSize: "16px",
    lineHeight: "18px",
    paddingLeft: "16px",
    paddingRight: "16px",
    display: "block",
    maxHeight: "56px",
    width: "100%",
    overflow: "hidden",
    color: "#343C3D",
    zIndex: 10,
    marginTop: "8px",
    fontWeight: "bold"
});

const Title = styled("div", {
    color: "#131212",
    marginLeft: "16px",
    marginRight: "16px",
    marginTop: "8px",
    fontSize: "24px",
    fontWeight: "bold",
    lineHeight: "28px",
    transition: "0.3s"
});

const ImageWrapper = styled("div", {
    paddingTop: "1px",
    "img": {
        width: "300px",
        display: "block",
        height: "202px",
        zIndex: 1,
        margin: "2px auto 0 auto"
    }
});

const BoxWrapper = styled("div", {
    width: "306px",
    height: "340px",
    overflow: "visible",
    position: "relative",
    backgroundColor: "white",
    "> a": {
        transform: "translateZ(0)",
        textDecoration: "none",
        display: "block",
        transition: "0.3s",
        width: "100%",
        height: "100%",

        "&:hover": {
            textDecoration: "none",
        }
    },

});

const Sponsor = styled("span", {
    display: "block",
    position: "absolute",
    left: "0",
    top: "0",
    padding: "0px 8px",
    backgroundColor: "#D8D8D8",
    color: "#8B8B8B",
    lineHeight: "16px",
    fontSize: "11px",
    zIndex: 4
});

const SponsorExtra = styled("span", {
    display: "block",
    position: "absolute",
    left: "3px",
    top: "3px",
    padding: "2px 12px",
    backgroundColor: "#D8D8D8",
    color: "#8B8B8B",
    lineHeight: "16px",
    fontSize: "12px",
    zIndex: 4
});

// zwyczajne bloki (duże)

/*
const Description = styled("span", {
    fontSize: "16px",
    lineHeight: "18px",
    paddingLeft: "10px",
    paddingRight: "10px",
    display: "block",
    maxHeight: "94px",
    width: "100%",
    overflow: "hidden",
    color: "#000",
    zIndex: 10,
    marginTop: "4px",
    fontFamily: '"Montserrat", sans-serif'
});

const Title = styled("div", {
    color: "#000",
    marginLeft: "10px",
    marginTop: "10px",
    fontSize: "22px",
    fontFamily: "$charis",
    fontWeight: "bold",
    lineHeight: "24px",
    transition: "0.3s"
});

const ImageWrapper = styled("div", {
    paddingTop: "1px",
    "img": {
        width: "316px",
        display: "block",
        height: "208px",
        zIndex: 1,
        margin: "2px auto 0 auto",
        "@bp4": {
            width: "460px",
            height: "250px"
        }
    }
});

const BoxWrapper = styled("div", {
    width: "320px",
    height: "325px",
    overflow: "visible",
    position: "relative",
    backgroundColor: "white",
    marginBottom: "20px",
    "@bp4": {
        marginBottom: "0px",
        width: "466px",
        height: "335px"
    },
    "> a": {
        transform: "translateZ(0)",
        textDecoration: "none",
        display: "block",
        transition: "0.3s",
        width: "100%",
        height: "100%",
        "&:hover": {
            position: "absolute",
            transform: "scale(1.01)"
        }
    }
});

*/

// specjalny wygląd

const ImageWrapperNotRegular = styled("div", {
    position: "relative",
    width: "calc(100% - 24px)",
    margin: "0px 12px 40px 12px",
    "@bp2": {
        width: "351px",
        margin: "0px auto 40px auto"
    },
    "@bp4": {
        width: "460px",
        height: "350px",
        boxShadow: "0px 0px 10px #000",
        margin: "0 auto"
    },
    "@bp5": {
        width: "625px",
        height: "350px"
    },
    "img": {
        width: "100%",
        display: "block",
        zIndex: 1,
        margin: "0 auto",
        "@bp4": {
            width: "460px",
            height: "350px",
            boxShadow: "0px 0px 10px #000"
        },
        "@bp5": {
            width: "625px",
            height: "350px"
        }
    },
    ".minisite-title": {
        background: "white",
        "@bp4": {
            position: "absolute",
            background: "transparent",
            left: "0px",
            right: "0px",
            bottom: "0px",
            paddingLeft: "26px",
            paddingRight: "26px",
            paddingBottom: "24px"
        },
        "span": {
            display: "block",
            padding: "12px",
            fontWeight: 700,
            color: "rgb(19, 18, 18)",
            backgroundColor: "rgb(255, 255, 255) !important",
            fontSize: "24px",
            letterSpacing: "0.2px",
            lineHeight: "32px",
            position: "relative",
            pointerEvents: "none",
            "@bp4": {
                display: "inline",
                padding: "0"
            }
        }
    }
});


export default MiniSite;
