import React from 'react';

import Link from '../editor/Link'
import FileUpload from '../editor/FileUpload'


const AMiniSiteEditor = (props) => {
    return (
        <div>
            {props.children}
            <div className="form-group">
                <label className="col-sm-3 control-label">Tytuł</label>
                <div className="col-sm-9">
                    <input type="text" name="title" className="form-control" defaultValue={props.settings.title} placeholder="Tytuł" />
                </div>
            </div>
            <div className="form-group">
                <label className="col-sm-3 control-label">Podtytuł (mniejsza czcionka)</label>
                <div className="col-sm-9">
                    <input type="text" name="description" className="form-control" defaultValue={props.settings.description} placeholder="Opis" />
                </div>
            </div>
            <div className="form-group">
                <label className="col-sm-3 control-label">Sponsor</label>
                <div className="col-sm-9">
                    <input type="text" name="sponsor" className="form-control" defaultValue={props.settings.sponsor} placeholder="Sponsor" />
                </div>
            </div>
            <hr />
            <FileUpload label="Obrazek" name="background-image" value={props.settings['background-image']} />
            <Link value={props.settings.link} />
        </div>
    );
}


export default AMiniSiteEditor;